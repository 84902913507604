import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import { BASE_URL, GET_BENEFICIARIES } from "../../utils/endpoints";
import styles from "./css/index.module.css";

const Beneficiaries = () =>{
    const [beneficiaries,setBeneficiaries] = useState([]);
    const [type,setType] = useState('orphan');

    useEffect(() => {
        let getBeneficiaries = async () => {
            await axios.get(BASE_URL + GET_BENEFICIARIES + '?type=' + type)
            .then((response) => {
                if(Array.isArray(response.data))
                    setBeneficiaries(response.data);
            }).catch((e) =>{
            
            })
        }
        getBeneficiaries();
    },[type])

    const returnedRoute = (id) => {

        let url = '/' + type + '/' + id;

        return url;

    }

    const setBeneficiariesType = (type) => {
        setType(type);
    }

    return<>
        <Header/>
        <div className={styles['container']}>
            <h2>Donors</h2>
            <div className={styles['types']}>
                <button className={type === 'orphan' ? styles['active'] : null} onClick={() => setBeneficiariesType('orphan')}>Orphans</button>
                <button className={type === 'widow' ? styles['active'] : null} onClick={() => setBeneficiariesType('widow')}>Widows</button>
            </div>
            <div>
                <h3>Name</h3>
            </div>
            <div>
                {beneficiaries.length !== 0 && 
                beneficiaries.map((item, i) => {
                return(
                <Link to={returnedRoute(item.id)} key={i}>
                    <label>{item.name}</label>
                    <label>Read more &gt;</label>
                </Link>)})}
            </div>
        </div>
    </>
};

export default Beneficiaries;