import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../Components/Header";
import { BASE_URL, DELETE_DONOR, GET_DONOR, GET_OBJ, UPDATE_DONOR } from "../../utils/endpoints";
import { CLASSES, PLEDGECATEGORY, PLEDGETYPE, STATES } from "../../utils/optionData";
import styles from "./css/index.module.css";

const Donor = () => {

    let { id } = useParams();
    const [donor,setDonor] = useState(undefined);
    const [isUpdating,setIsUpdating] = useState(false);
    const [isDeleting,setIsDeleting] = useState(false);
    const [isDeleted,setIsDeleted] = useState(false);
    const [schools, setSchools] = useState([]);
    const [programmes, setProgrammes] = useState([]);
    const [cycles, setCycles] = useState([]);

    useEffect(() => {
        let getSchool = async () => {
            await axios.get(BASE_URL + GET_OBJ['School'])
            .then((response) => {
                if(Array.isArray(response.data))
                    setSchools(response.data);
            }).catch((e) =>{
            
            })
        }
        getSchool();

        let getProgramme = async () => {
            await axios.get(BASE_URL + GET_OBJ['Programme'])
            .then((response) => {
                if(Array.isArray(response.data))
                    setProgrammes(response.data);
            }).catch((e) =>{
            
            })
        }
        getProgramme();

        let getCycle = async () => {
            await axios.get(BASE_URL + GET_OBJ['Cycle'])
            .then((response) => {
                if(Array.isArray(response.data))
                    setCycles(response.data);
            }).catch((e) =>{
            
            })
        }
        getCycle();
    },[]);

    const handleInputChange = (e) => {
        setDonor((prevState) => {
        return {
            ...prevState,
            [e.target.name]: e.target.value,
        };
        });
    };

    const handlePledgesChange = (parentIndex, event) => {
        let pledgesArray = {...donor};
        pledgesArray["pledges"][parentIndex][event.target.name] = event.target.value;
        setDonor(pledgesArray);
    };

    const handleOrphanChange = (parentIndex, childIndex, event) => {
        let pledgesArray = {...donor};
        pledgesArray["pledges"][parentIndex]["orphans"][childIndex][event.target.name] = event.target.value;
        setDonor(pledgesArray);
    };

    const handleWidowChange = (parentIndex, childIndex, event) => {
        let pledgesArray = {...donor};
        pledgesArray["pledges"][parentIndex]["widows"][childIndex][event.target.name] = event.target.value;
        setDonor(pledgesArray);
    };

    const handleContributionsChange = (parentIndex, childIndex, event) => {
        let pledgesArray = {...donor};
        pledgesArray["pledges"][parentIndex]["contributions"][childIndex][event.target.name] = event.target.value;
        setDonor(pledgesArray);
    };

    const addOrphanField = (parentIndex) => {
        let donorArray = {...donor};
        let orphanArray = [];
        if(donorArray["pledges"][parentIndex]["orphans"] === undefined){
            orphanArray = [{name: "", school: "", state: "", currentClass: "", adoptionClass: "", cycle: "", new : 1}];
        }else{
            orphanArray = [
                ...donor["pledges"][parentIndex]["orphans"],
                {name: "", school: "", state: "", currentClass: "", adoptionClass: "", cycle: "", new : 1}
            ];
        }
        let pledgecategory = donor["pledges"][parentIndex].pledgecategory;
        if(orphanArray.length > 0 && donor["pledges"][parentIndex].widows.length > 0)
        {
            pledgecategory = PLEDGECATEGORY[2];
        }
        else if(orphanArray.length > 0 && 
            (donor["pledges"][parentIndex]?.widows.length === 0 || 
            donor["pledges"][parentIndex]?.widows === undefined))
        {
            pledgecategory = PLEDGECATEGORY[0];
        }
        donorArray["pledges"][parentIndex].pledgecategory = pledgecategory;
        donorArray["pledges"][parentIndex].orphans = orphanArray;
        setDonor(donorArray);
      }


    const removeOrphanField = (parentIndex,childIndex) => {
        let donorArray = {...donor};
        let deletedOrphan = donorArray["pledges"][parentIndex]["orphans"].splice(childIndex, 1);
        let pledgecategory = donor["pledges"][parentIndex].pledgecategory;
        if(donorArray["pledges"][parentIndex]["orphans"].length === 0 &&
         donor["pledges"][parentIndex]["widows"].length > 0)
        {
            pledgecategory = PLEDGECATEGORY[1];
        }
        else if(donorArray["pledges"][parentIndex]["orphans"].length === 0 && 
        (donor["pledges"][parentIndex]["widows"].length === 0 || 
        donor["pledges"][parentIndex]["widows"] === undefined))
        {
            pledgecategory = PLEDGECATEGORY[3];
        }
        donorArray["pledges"][parentIndex].pledgecategory = pledgecategory;
        donorArray["pledges"][parentIndex].deletedItem = {
            ...donor["pledges"][parentIndex].deletedItem,
            orphans : donor["pledges"][parentIndex]?.deletedItem?.orphans === undefined ? 
                deletedOrphan : [...donor["pledges"][parentIndex].deletedItem.orphans,...deletedOrphan]
        }
        setDonor(donorArray);
    }

    const addContributionsField = (parentIndex) => {
        let donorArray = {...donor};
        let contributionsArray = [];
        if(donorArray["pledges"][parentIndex]["contributions"] === undefined){
            contributionsArray = [{datedonated: "", amount: "", new : 1}];
        }else{
            contributionsArray = [
                ...donor["pledges"][parentIndex]["contributions"],
                {datedonated: "", amount: "", new : 1}
            ];
        }
        donorArray["pledges"][parentIndex].contributions = contributionsArray;
        setDonor(donorArray);
      }


    const removeContributionsField = (parentIndex,childIndex) => {
        let donorArray = {...donor};
        let deletedContributions = donorArray["pledges"][parentIndex]["contributions"].splice(childIndex, 1);
        donorArray["pledges"][parentIndex].deletedItem = {
            ...donor["pledges"][parentIndex].deletedItem,
            contributions : donor["pledges"][parentIndex]?.deletedItem?.contributions === undefined ? 
            deletedContributions : [...donor["pledges"][parentIndex].deletedItem.contributions, ...deletedContributions]
        }
        setDonor(donorArray);
    }

    const addWidowField = (parentIndex) => {
        let donorArray = {...donor};
        let widowsArray = [];
        if(donorArray["pledges"][parentIndex]["widows"] === undefined){
            widowsArray = [{name: "", state: "", programme: "", programmeDate: "", startupGiven: "", cycle: "", new : 1}];
        }else{
            widowsArray = [
                ...donor["pledges"][parentIndex]["widows"],
                {name: "", state: "", programme: "", programmeDate: "", startupGiven: "", cycle: "", new : 1}
            ];
        }
        let pledgecategory = donor["pledges"][parentIndex].pledgecategory;
        if(widowsArray.length > 0 && donor["pledges"][parentIndex].orphans.length > 0)
        {
            pledgecategory = PLEDGECATEGORY[2];
        }
        else if(widowsArray.length > 0 && 
            (donor["pledges"][parentIndex]?.orphans.length === 0 || donor["pledges"][parentIndex]?.orphans === undefined))
        {
            pledgecategory = PLEDGECATEGORY[1];
        }
        donorArray["pledges"][parentIndex].pledgecategory = pledgecategory;
        donorArray["pledges"][parentIndex].widows = widowsArray;
        setDonor(donorArray);
      }


    const removeWidowField = (parentIndex,childIndex) => {
        let donorArray = {...donor};
        let deletedWidow = donorArray["pledges"][parentIndex]["widows"].splice(childIndex, 1);
        let pledgecategory = donor["pledges"][parentIndex].pledgecategory;
        if(donorArray["pledges"][parentIndex]["widows"].length === 0 &&
        donor["pledges"][parentIndex]["orphans"].length > 0)
        {
            pledgecategory = PLEDGECATEGORY[0];
        }
        else if(donorArray["pledges"][parentIndex]["widows"].length === 0 && 
        (donor["pledges"][parentIndex]["orphans"].length === 0 || 
        donor["pledges"][parentIndex]["orphans"] === undefined))
        {
            pledgecategory = PLEDGECATEGORY[3];
        }
        donorArray["pledges"][parentIndex].pledgecategory = pledgecategory;
        donorArray["pledges"][parentIndex].deletedItem = {
            ...donor["pledges"][parentIndex].deletedItem,
            widows : donor["pledges"][parentIndex]?.deletedItem?.widows === undefined ? 
                deletedWidow : [...donor["pledges"][parentIndex].deletedItem.widows, ...deletedWidow]
        }
        setDonor(donorArray);
    }

    const addPledgesField = () => {
        let donorArray = JSON.parse(JSON.stringify(donor));
        let newPledgeField = donorArray.pledges.pop();
        newPledgeField["year"] = "";
        newPledgeField["pledgeamount"] = "";
        newPledgeField["contributions"] = [{
            datedonated: "", 
            amount: "", 
            new : 1
        }];
        newPledgeField["new"] = 1;
        donorArray.pledges = [...donor.pledges, newPledgeField];
        setDonor(donorArray);
      }
    
    const removePledgesField = (parentIndex) => {
        let donorArray = {...donor};
        let deletedPledge = donorArray.pledges.splice(parentIndex, 1);
        donorArray.deletedItem = {
            ...donor.deletedItem,
            pledges : donor?.deletedItem?.pledges === undefined ? 
            deletedPledge : [...donor.deletedItem.pledges, ...deletedPledge]
        }
        setDonor(donorArray);
    }

    let getDonor = useCallback(async () => {
        await axios.get(BASE_URL + GET_DONOR + "?id=" + id)
        .then((response) => {
            setDonor(response.data[0]);
        }).catch((e) =>{
        
        })
    },[id]);

    useEffect(() => {   
        getDonor();
    },[getDonor])

    let deleteDonor = async () => {
        await axios.get(BASE_URL + DELETE_DONOR + "?id=" + id)
        .then((response) => {
            setDonor(undefined);
            setIsDeleted(true);
            document.body.style.overflow = 'unset';
            setIsDeleting(false);
        }).catch((e) =>{
        })
    }

    let updateDonor = async () => {
        console.log(donor);
        const jsonObj = JSON.stringify(donor);
        await axios.post(BASE_URL + UPDATE_DONOR,jsonObj)
        .then((response) => {
            //console.log(response);
            getDonor();
            setIsUpdating(false);
        }).catch((e) =>{
        })
    }

    return<>
    <Header/>
    {donor !== undefined &&
    <div className={styles["container"]}>
        <h1>Donor</h1>
        <div>
            <label>Name</label>
            {isUpdating ? 
            <input name="name" value={donor.name} onChange={handleInputChange}/> :
            <span>{donor.name}</span>
            }
        </div>
        <div>
            <label>Phone Number</label>
            {isUpdating ? 
            <input name="phonenumber" value={donor.phonenumber} onChange={handleInputChange} type={"tel"}/> :
            <span>{donor.phonenumber}</span>
            }
        </div>
        <div>
            <label>Email</label>
            {isUpdating ? 
            <input name="email" value={donor.email} onChange={handleInputChange}/> :
            <span>{donor.email}</span>
            }
        </div>
        <div className={styles["pledges"]}>
            <h2>Pledges</h2>
            {donor.pledges.map((pledge, parentIndex) => (
            <div key={parentIndex}  className={styles["pledge-container"]}>
                <h3>Pledge {parentIndex + 1}.</h3>
                <div className={styles["pledge"]}> 
                    <div>
                        <label>Pledge Type</label>
                        {isUpdating ? 
                        <select name="pledgetype" onChange={(e) => handlePledgesChange(parentIndex, e)} value={pledge.pledgetype}>
                            <option value="">Select Pledge Type</option>
                            {PLEDGETYPE.map((pledgetype, i) => (
                                <option value={pledgetype} key={i}>{pledgetype}</option>
                            ))}
                        </select> :
                        <span>{pledge.pledgetype}</span>
                        }
                    </div>
                    <div>
                        <label>Pledge Category</label>
                        {isUpdating ? 
                        <select name="pledgecategory" onChange={(e) => handlePledgesChange(parentIndex, e)} value={pledge.pledgecategory}>
                            <option value="">Select Category</option>
                            {PLEDGECATEGORY.map((pledgecategory, i) => (
                                <option value={pledgecategory} key={i}>{pledgecategory}</option>
                            ))}
                        </select> :
                        <span>{pledge.pledgecategory}</span>
                        }
                    </div>
                    <div>
                        <label>Year</label>
                        {isUpdating ? 
                        <input name="year" value={pledge.year} onChange={(e) => handlePledgesChange(parentIndex, e)} type={"number"}/> :
                        <span>{pledge.year}</span>
                        }
                    </div>
                    <div>
                        <label>Pledge Amount</label>
                        {isUpdating ? 
                        <input name="pledgeamount" value={pledge.pledgeamount} onChange={(e) => handlePledgesChange(parentIndex, e)} type={"number"}/> :
                        <span>{pledge.pledgeamount}</span>
                        }
                    </div>
                </div>
                { (pledge.orphans !== undefined || pledge.pledgecategory === "orphan" || pledge.pledgecategory === "both") &&
                <div className={styles["orphan"]}>
                {(isUpdating || pledge.orphans.length > 0) && <h3>Orphans</h3>}
                    {pledge.orphans !== undefined && 
                    pledge.orphans.map((orphan, childIndex) => (
                    <div key={childIndex}>
                        <h4>{childIndex + 1}.</h4>
                        <div>
                            <label>Name</label>
                            {isUpdating && orphan.new === 1? 
                            <input name="name" value={orphan.name} onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)}/> :
                            <span>{orphan.name}</span>
                            }
                        </div>
                        <div>
                            <label>School</label>
                            {isUpdating ? 
                            <select name="school" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.school}>
                                <option value={""}>Select School</option>
                                {schools.map((school, i) => (
                                    <option value={school.name} key={i}>{school.name}</option>
                                ))}
                            </select> :
                            <span>{orphan.school}</span>
                            }
                        </div>
                        <div>
                            <label>State</label>
                            {isUpdating ? 
                            <select name="state" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.state}>
                                <option value={""}>Select State</option>
                                {STATES.map((state, i) => (
                                    <option value={state} key={i}>{state}</option>
                                ))}
                            </select> :
                            <span>{orphan.state}</span>
                            }
                        </div>
                        <div>
                            <label>Current Class</label>
                            {isUpdating ? 
                            <select name="currentClass" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.currentClass}>
                                <option value={""}>Select Current Class</option>
                                {CLASSES.map((classlevel, i) => (
                                    <option value={classlevel} key={i}>{classlevel}</option>
                                ))}
                            </select> :
                            <span>{orphan.currentClass}</span>
                            }
                        </div>
                        <div>
                            <label>Adoption Class</label>
                            {isUpdating ? 
                            <select name="adoptionClass" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.adoptionClass}>
                                <option value={""}>Select Adoption Class</option>
                                {CLASSES.map((classlevel, i) => (
                                    <option value={classlevel} key={i}>{classlevel}</option>
                                ))}
                            </select> :
                            <span>{orphan.adoptionClass}</span>
                            }
                        </div>
                        <div>
                            <label>Cycle</label>
                            {isUpdating ? 
                            <select name="cycle" onChange={(e) => handleOrphanChange(parentIndex, childIndex, e)} value={orphan.cycle}>
                                <option value={""}>Select Cycle</option>
                                {cycles.map((cycle, i) => (
                                    <option value={cycle.name} key={i}>{cycle.name}</option>
                                ))}
                            </select> :
                            <span>{orphan.cycle}</span>
                            }
                        </div>
                        <div>
                            <label>Year</label>
                            <span>{pledge.year}</span>
                        </div>
                        {isUpdating && <button onClick={() => removeOrphanField(parentIndex, childIndex)}>Delete Orphan</button>}
                    </div>
                    ))}
                    {isUpdating && <button onClick={() => addOrphanField(parentIndex)}>Add Orphan</button>}
                </div>}
                { (pledge.widows !== undefined || pledge.pledgecategory === "widow" || pledge.pledgecategory === "both") &&
                <div className={styles["widow"]}>
                    {(isUpdating || pledge.widows.length > 0) && <h3>Widows</h3>}
                    {pledge.widows !== undefined &&
                    pledge.widows.map((widow, childIndex) => (
                    <div key={childIndex}>
                        <h4>{childIndex + 1}.</h4>
                        <div>
                            <label>Name</label>
                            {isUpdating && widow.new === 1 ? 
                            <input name="name" value={widow.name} onChange={(e) => handleWidowChange(parentIndex, childIndex, e)}/> :
                            <span>{widow.name}</span>
                            }
                        </div>
                        <div>
                            <label>State</label>
                            {isUpdating ? 
                            <select name="state" type="text" onChange={(e) => handleWidowChange(parentIndex, childIndex, e)} value={widow.state}>
                                <option value={""}>Select State</option>
                                {STATES.map((state, i) => (
                                    <option value={state} key={i}>{state}</option>
                                ))}
                            </select> :
                            <span>{widow.state}</span>
                            }
                        </div>
                        <div>
                            <label>Programme</label>
                            {isUpdating ? 
                            <select name="programme"  onChange={(e) => handleWidowChange(parentIndex, childIndex, e)} value={widow.programme}>
                                <option value={""}>Select Programme</option>
                                {programmes.map((programme, i) => (
                                    <option value={programme.name} key={i}>{programme.name}</option>
                                ))}
                            </select> :
                            <span>{widow.programme}</span>
                            }
                        </div>
                        <div>
                            <label>Programme Date</label>
                            {isUpdating ? 
                            <input name="programmeDate" value={widow.programmeDate} onChange={(e) => handleWidowChange(parentIndex, childIndex, e)} type={"date"}/> :
                            <span>{widow.programmeDate}</span>
                            }
                        </div>
                        <div>
                            <label>Startup Given</label>
                            {isUpdating ? 
                            <input name="startupGiven" value={widow.startupGiven} onChange={(e) => handleWidowChange(parentIndex, childIndex, e)} type={"number"}/> :
                            <span>{widow.startupGiven}</span>
                            }
                        </div>
                        <div>
                            <label>Cycle</label>
                            {isUpdating ? 
                            <select name="cycle" onChange={(e) => handleWidowChange(parentIndex, childIndex, e)} value={widow.cycle}>
                                <option value={""}>Select Cycle</option>
                                {cycles.map((cycle, i) => (
                                    <option value={cycle.name} key={i}>{cycle.name}</option>
                                ))}
                            </select> :
                            <span>{widow.cycle}</span>
                            }
                        </div>
                        <div>
                            <label>Year</label>
                            <span>{pledge.year}</span>
                        </div>
                        {isUpdating && <button onClick={() => removeWidowField(parentIndex, childIndex)}>Delete Widow</button>}
                    </div>
                    ))}
                    {isUpdating && <button onClick={() => addWidowField(parentIndex)}>Add Widow</button>}
                </div>}
                {pledge.contributions !== undefined &&
                <div className={styles["donated"]}>
                    <h3>Donor Contributions</h3>
                    {pledge.contributions.map((contribution, childIndex) => (
                    <div key={childIndex}>
                        <h4>{childIndex + 1}.</h4>
                        <div>
                            <label>Date Donated</label>
                            {isUpdating ? 
                            <input name="datedonated" value={contribution.datedonated} onChange={(e) => handleContributionsChange(parentIndex, childIndex, e)} type={"date"}/> :
                            <span>{contribution.datedonated}</span>
                            }
                        </div>
                        <div>
                            <label>Amount</label>
                            {isUpdating ? 
                            <input name="amount" value={contribution.amount} onChange={(e) => handleContributionsChange(parentIndex, childIndex, e)} type={"number"}/> :
                            <span>{contribution.amount}</span>
                            }
                        </div>
                        {isUpdating && <button onClick={() => removeContributionsField(parentIndex, childIndex)}>Delete Donation</button>}
                    </div>
                    ))}
                    {isUpdating && <button onClick={() => addContributionsField(parentIndex)}>Add Donation</button>}
                </div>
                }
                {isUpdating && <button className={styles["remove-pledge"]} onClick={() => removePledgesField(parentIndex)}>Remove Pledge</button>}
            </div>
            ))}
            {isUpdating && <button className={styles["add-pledge"]} onClick={addPledgesField}>Add Pledge</button>}
        </div>
        {isUpdating &&
        <>
        <button onClick={updateDonor}>
            Update Fields
        </button>
        <button onClick={() => {setIsUpdating(false);getDonor();}}>
            Cancel Update
        </button>
        </>}
        { !isUpdating &&
        <> 
            <button onClick={() => setIsUpdating(true)}>Update Donor</button>
            <button onClick={() => {document.body.style.overflow = 'hidden';setIsDeleting(true);}}>
                Delete
            </button>
        </>
        }
    </div>}
    {isDeleting &&
    <div className={styles["modal-container"]}>
        <div>
            <label>Are you sure you want to delete?</label>
            <div>
                <button onClick={deleteDonor}>Yes</button>
                <button onClick={() => {document.body.style.overflow = 'unset';setIsDeleting(false)}}>No</button>
            </div>
        </div>
    </div>}
    { isDeleted && 
    <div>
        <h2>Donor successfully Deleted</h2>
        <Link to={'/donors'}>Go back to Donors List</Link>
    </div>}
    </>
}

export default Donor;