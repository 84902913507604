export const STATES = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT - Abuja",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara"
  ];

  export const CLASSES = [
    "Primary 1",
    "Primary 2",
    "Primary 3",
    "Primary 4",
    "Primary 5",
    "Primary 6",
    "JSS 1",
    "JSS 2",
    "JSS 3",
    "SS 1",
    "SS 2",
    "SS 3"
  ];

  export const CYCLES = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5"
  ];
  
  export const WIDOWSPROGRAMME = [
    "Programme 1",
    "Programme 2",
    "Programme 3",
    "Programme 4",
    "Programme 5"
  ];

  export const PLEDGETYPE = [
    "monthly",
    "quarterly",
    "bi-annually",
    "annually",
    "one-off"
  ]

  export const PLEDGECATEGORY = [
    "orphan",
    "widow",
    "both",
    "none"
  ]

  export const SCHOOLS = [
    "Aminu School",
    "Ahmadu Bello School",
    "Murtala Mohammed School",
    "Zaria School",
    "Abdulai Amin School",
    "Grammar"
  ]