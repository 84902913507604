import React, { useContext, useState } from "react";
import styles from "./css/index.module.css";
import Header from "../../Components/Header";
import AuthContext from "../../store/AuthContext";
import { BASE_URL, LOGIN } from "../../utils/endpoints";
import axios from "axios";

const Login = () => {
    const AuthCtx = useContext(AuthContext);
    const [userData, setUserData] = useState({ username: "", password: "" });
    const [errorMessage, setErrorMessage] = useState("");

    const handleInputChange = (e) => {
        setUserData((prevState) => {
        return {
            ...prevState,
            [e.target.name]: e.target.value,
        };
        });
    };

    const loginAction = async (e) => {
        e.preventDefault();
    
        const jsonObj = JSON.stringify(userData);
        await axios.post(BASE_URL + LOGIN, jsonObj)
          .then((response) => {
             if(response.data.length > 0){
                AuthCtx.login(response.data[0].id)
             }
          }).catch((e) =>{
            setErrorMessage("Invalid username/password");
          })
    }

    return(
    <>
    <Header/>
    <div className={styles["form-container"]}>
        <div>
            <label>Username</label>
            <input type="text" name="username" onChange={handleInputChange}/>
        </div>
        <div>
            <label>Password</label>
            <input type="password" name="password"  onChange={handleInputChange}/>
        </div>
        <div>
            <button onClick={loginAction}>
                Login
            </button>
        </div>
        <span>{errorMessage}</span>
    </div>
    </>
    )
}
export default Login;