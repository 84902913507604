//export const BASE_URL = "http://localhost/tfolcmware/";
export const BASE_URL = "https://mware.tfolcvineyardproject.org/";

export const LOGIN = "login/";

export const INSERT_DONOR = "insertDonor/";

export const GET_DONORS = "getDonors/";

export const GET_BENEFICIARIES = "getBeneficiaries/";

export const GET_DONOR = "getDonor/";

export const GET_BENEFICIARY = "getBeneficiary/";

export const DELETE_DONOR = "deleteDonor/";

export const UPDATE_DONOR = "updateDonor/";

export const GET_DONOR_YEAR = "getAllDonorsByYear/";

export const GET_DONATION_YEAR = "getAllDonationByYear/";

export const GET_TOTAL_PLEDGES_BY_DONORS = "getTotalPledgesByDonors/";

export const INSERT_OBJ = {
    School : "insertSchool/",
    Programme : "insertProgramme/",
    Cycle : "insertCycle/"
};

export const GET_OBJ = {
    School : "getSchool/",
    Programme : "getProgramme/",
    Cycle : "getCycle/"
};
