import './App.css';
import Home from './Pages/Home';
import Login from './Pages/Login';
import ProtectedPages from './Components/ProtectedPages';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContextProvider } from './store/AuthContext';
import Donors from './Pages/Donors';
import Donor from './Pages/Donor';
import Beneficiaries from './Pages/Beneficiaries';
import Beneficiary from './Pages/Beneficiary';
import Admin from './Pages/Admin';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthContextProvider>
          <Routes>
            <Route element={<ProtectedPages>
                              <Home/>
                            </ProtectedPages>} path="/"/>
            <Route element={<ProtectedPages>
                              <Donors/>
                            </ProtectedPages>} path="/donors"/>
            <Route element={<ProtectedPages>
                              <Beneficiaries/>
                            </ProtectedPages>} path="/beneficiaries"/>
            <Route element={<ProtectedPages>
                              <Admin/>
                            </ProtectedPages>} path="/admin"/>
            <Route element={<ProtectedPages>
                              <Donor/>
                            </ProtectedPages>} path="/donor/:id"/>
            <Route element={<ProtectedPages>
                              <Beneficiary/>
                            </ProtectedPages>} path="/:beneficiary/:id"/>
            <Route element={<Login/>} path="login"/>
          </Routes>
        </AuthContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
