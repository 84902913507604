export const downloadJsonArrayAsCsv = (csvString, filename) => {

    // Create blob and download
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

}

export const createCsvString = (jsonArray) => {
    let csvString = '';

    const headers = Object.keys(jsonArray[0]);

    jsonArray.forEach(obj => {
        // Iterate over each property of the object
        const rowValues = headers.map(header => obj[header]);
        csvString += rowValues.join(',') + '\n';
    });

    return csvString;
}