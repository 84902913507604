import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import { BASE_URL, GET_DONORS } from "../../utils/endpoints";
import styles from "./css/index.module.css";

const Donors = () =>{
    const [donors,setDonors] = useState([]);

    useEffect(() => {
        let getDonors = async () => {
            await axios.get(BASE_URL + GET_DONORS)
            .then((response) => {
                setDonors(response.data);
            }).catch((e) =>{
            
            })
        }
        getDonors();
    },[])

    return<>
        <Header/>
        <div className={styles['container']}>
            <h2>Donors</h2>
            <div>
                <h3>Name</h3>
            </div>
            <div>
                {donors.length !== 0 && 
                donors.map((item, i) => {
                return(
                <Link to={'/donor/' + item.id} key={i}>
                    <label>{item.name}</label>
                    <label>Read more &gt;</label>
                </Link>)})}
            </div>
        </div>
    </>
};

export default Donors;