import React,{ useContext } from 'react';
import AuthContext from '../../store/AuthContext';
import { Navigate } from "react-router-dom";

const ProtectedPages = ({ children }) =>{
    
    const AuthCtx = useContext(AuthContext);

    if(!AuthCtx.isLoggedIn){
        return <Navigate to="/login" />;
    }

    return children;

}
export default ProtectedPages