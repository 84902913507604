import React, { useContext } from "react";
import logo from "./../../img/tfolc.jpg";
import styles from "./css/index.module.css";
import AuthContext from "../../store/AuthContext";
import { Link } from "react-router-dom";

const Header = () =>{
    const AuthCtx = useContext(AuthContext);

    return(
    <div className={styles["header"]}>
        <div>
            <img src={logo} alt="logo"/>
            <h1>TFOLC Vineyard</h1>
        </div>
        { AuthCtx.isLoggedIn && 
            <div className={styles["nav"]}>
                <Link to={'/'}>
                    Upload Donor
                </Link>
                <Link to={'/donors'}>
                    Donor List
                </Link>
                <Link to={'/beneficiaries'}>
                    Beneficiaries List
                </Link>
                <Link to={'/admin'}>
                    Admin
                </Link>
            </div>
        }
        { AuthCtx.isLoggedIn && <button onClick={() => {AuthCtx.logout()}}>Logout</button>}
    </div>
    );
}
export default Header